<div
    class="resource-selector"
    [hidden]="hidden"
>
    <nav
        class="m-tabs-picker resource-selector__header"
        *ngIf="
            measuringPointsSelection ||
            groupsSelection ||
            projectSelection ||
            organizationsSelection ||
            devicesSelection ||
            scenariosSelection
        "
    >
        <ul>
            <li *ngIf="measuringPointsSelection">
                <a
                    id="measuring_points_context"
                    [ngClass]="{ 'is-active': selectionMode == 0 }"
                    [style.color]="isMeasuringPointsInError(containingFormSubmitted) ? 'red' : ''"
                    (click)="setSelectionMode(0)"
                >
                    {{
                        (multipleMeasuringPointsSelection ? "general.measuringPoints" : "general.measuringPoint")
                            | translate
                    }}
                    ({{ currentlySelectedMeasuringPoints }})
                </a>
            </li>
            <li *ngIf="devicesSelection">
                <a
                    id="devices_context"
                    [ngClass]="{ 'is-active': selectionMode == 4 }"
                    [style.color]="isDevicesInError(containingFormSubmitted) ? 'red' : ''"
                    (click)="setSelectionMode(4)"
                >
                    {{ (multipleDevicesSelection ? "general.devices" : "general.device") | translate }} ({{
                        currentlySelectedDevices
                    }})
                </a>
            </li>
            <li *ngIf="groupsSelection">
                <a
                    id="groups_context"
                    [ngClass]="{ 'is-active': selectionMode == 1 }"
                    [style.color]="isGroupsInError(containingFormSubmitted) ? 'red' : ''"
                    (click)="setSelectionMode(1)"
                >
                    {{ (multipleGroupsSelection ? "general.groups" : "general.group") | translate }} ({{
                        currentlySelectedGroups
                    }})
                </a>
            </li>
            <li *ngIf="projectSelection">
                <a
                    id="projects_context"
                    [ngClass]="{ 'is-active': selectionMode == 2 }"
                    [style.color]="isProjectsInError(containingFormSubmitted) ? 'red' : ''"
                    (click)="setSelectionMode(2)"
                >
                    {{ (multipleProjectsSelection ? "projects.title" : "general.project") | translate }} ({{
                        currentlySelectedProjects
                    }})
                </a>
            </li>
            <li *ngIf="organizationsSelection">
                <a
                    id="organizations_context"
                    [ngClass]="{ 'is-active': selectionMode == 3 }"
                    [style.color]="isOrganizationsInError(containingFormSubmitted) ? 'red' : ''"
                    (click)="setSelectionMode(3)"
                >
                    {{ (organizationsSelection ? "organizations.title" : "general.organization") | translate }} ({{
                        currentlySelectedOrganizations
                    }})
                </a>
            </li>
            <li *ngIf="scenariosSelection">
                <a
                    id="scenarios_context"
                    [ngClass]="{ 'is-active': selectionMode == 5 }"
                    [style.color]="isScenariosInError(containingFormSubmitted) ? 'red' : ''"
                    (click)="setSelectionMode(5)"
                >
                    {{ (multipleScenariosSelection ? "general.scenarios" : "general.scenario") | translate }} ({{
                        currentlySelectedScenarios
                    }})
                </a>
            </li>
        </ul>

        <app-icon-button
            id="buttonAddResource"
            *ngIf="!isMapOpen"
            [fill]="isInputInError(containingFormSubmitted) ? 'red' : ''"
            [styleClass]="isMapOpen ? 'm-btn m-btn-square hover' : 'm-btn m-btn-square'"
            [text]="'general.add' | translate"
            (click)="updateMapDetail()"
            icon="add"
        >
        </app-icon-button>
    </nav>

    <div class="resource-selector__content">
        <app-select-measuring-points
            [allowedDirections]="allowedDirections"
            [deleteCommand]="deleteCommand"
            [emptyMessage]="false"
            [footer]="false"
            [hidden]="!measuringPointsSelection || selectionMode !== 0"
            [hideDirectionFilters]="!measuringPointsWithDirection"
            [reorderable]="true"
            [scrollHeight]="'flex'"
            [selectionMode]="multipleMeasuringPointsSelection ? 'multiple' : 'single'"
        >
        </app-select-measuring-points>

        <app-select-groups
            [deleteCommand]="deleteCommand"
            [emptyMessage]="false"
            [footer]="false"
            [hidden]="!groupsSelection || selectionMode !== 1"
            [hideDirectionFilters]="!measuringPointsWithDirection"
            [scrollHeight]="'flex'"
            [selectionMode]="multipleGroupsSelection ? 'multiple' : 'single'"
            [unlinkCommand]="true"
        >
        </app-select-groups>

        <app-select-projects
            [deleteCommand]="deleteCommand"
            [emptyMessage]="false"
            [footer]="false"
            [hidden]="!projectSelection || selectionMode !== 2"
            [reorderable]="true"
            [scrollHeight]="'flex'"
            [selectionMode]="multipleProjectsSelection ? 'multiple' : 'single'"
            [unlinkCommand]="true"
        >
        </app-select-projects>

        <app-select-organizations
            [deleteCommand]="deleteCommand"
            [emptyMessage]="false"
            [footer]="false"
            [hidden]="!organizationsSelection || selectionMode !== 3"
            [scrollHeight]="'flex'"
            [selectionMode]="multipleOrganizationsSelection ? 'multiple' : 'single'"
        >
        </app-select-organizations>

        <app-select-devices
            [deleteCommand]="deleteCommand"
            [emptyMessage]="false"
            [footer]="false"
            [hidden]="!devicesSelection || selectionMode !== 4"
            [scrollHeight]="'flex'"
            [selectionMode]="multipleDevicesSelection ? 'multiple' : 'single'"
        >
        </app-select-devices>

        <app-select-scenarios
            [deleteCommand]="deleteCommand"
            [emptyMessage]="false"
            [footer]="false"
            [hidden]="!scenariosSelection || selectionMode !== 5"
            [scrollHeight]="'flex'"
            [selectionMode]="multipleScenariosSelection ? 'multiple' : 'single'"
        >
        </app-select-scenarios>
    </div>
</div>
