<p-table
    #tableRef
    [columns]="relevantColumns"
    [lazy]="isLazy"
    [loading]="loading"
    [pageLinks]="pageLinks"
    [paginator]="paginator"
    [rows]="internalRowCount"
    [scrollable]="scrollable"
    [scrollHeight]="scrollHeight"
    [selectionMode]="selectionMode"
    [stateKey]="stateKey"
    [styleClass]="styleClass"
    [totalRecords]="total"
    [value]="data"
    (onLazyLoad)="loadTableRows($event)"
    (onPage)="onPage($event)"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowDeselect($event)"
    dataKey="id"
>
    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td
                class="m-table-empty-message"
                [attr.colspan]="relevantColumns.length"
            >
                {{ "table.empty" | translate }}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total"> {{ "table.total" | translate }}: {{ total }} </ng-container>
    </ng-template>

    <ng-template
        *ngIf="footer && !paginator"
        pTemplate="summary"
    >
        <ng-container *ngIf="total"> {{ "table.total" | translate }}: {{ total }} </ng-container>
    </ng-template>

    <ng-template
        *ngIf="headers"
        pTemplate="header"
    >
        <tr>
            <th
                *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable"
            >
                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{ column.header | translate }}
                        </span>

                        <p-sortIcon
                            *ngIf="sortable && column.sortable"
                            [field]="column.field"
                        ></p-sortIcon>

                        <app-table-filter
                            class="m-column-filter"
                            *ngIf="filter"
                            [column]="column"
                            [table]="table"
                        >
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template
        let-report
        pTemplate="body"
    >
        <tr [pSelectableRow]="report">
            <td
                *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="{ width: ['commands', 'statusId'].includes(column.field) ? '0' : null }"
                [ngSwitch]="column.field"
            >
                <ng-container *ngSwitchCase="'statusId'">
                    <div class="flex align-center">
                        <app-svg-icon
                            *ngIf="getReportStatusIcon(report) && report?.statusId !== 'failed'; else failedStatus"
                            [fill]="getReportStatusColor(report)"
                            [icon]="getReportStatusIcon(report)"
                        >
                        </app-svg-icon>

                        <ng-template #failedStatus>
                            <a>
                                <app-svg-icon
                                    [fill]="getReportStatusColor(report)"
                                    [icon]="getReportStatusIcon(report)"
                                    [pTooltip]="report?.errorMessage ?? ''"
                                    tooltipEvent="hover"
                                >
                                </app-svg-icon>
                            </a>
                        </ng-template>

                        <app-loading-spinner *ngIf="!getReportStatusIcon(report)"> </app-loading-spinner>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'typeId'">
                    {{ getLabel(report) }}
                </ng-container>

                <ng-container *ngSwitchCase="'creator'">
                    <app-navigator [user]="report.creator"></app-navigator>
                </ng-container>

                <ng-container *ngSwitchCase="'createDate'">
                    {{ report.createDate | momentDateTime: true : false : translateService.currentLang }}
                </ng-container>

                <ng-container *ngSwitchCase="'fileSize'">
                    <ng-container *ngIf="report.fileSize">
                        {{ report.fileSize / 1024 / 1024 | number: "1.0-2" }} {{ "measurements.mb" | translate }}
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'duration'">
                    <ng-container *ngIf="report.duration">
                        {{ report.duration | momentDuration: true }}
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'organizationId'">
                    <app-navigator
                        [enabled]="organizationNavigation"
                        [organizationId]="report.organizationId"
                    ></app-navigator>
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="flex align-center">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button
                                *ngIf="command.rowValidFunc(report)"
                                [disabled]="command.disabledFunc()"
                                [icon]="command.icon"
                                [text]="command.text"
                                (click)="command.click(report)"
                            >
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{ report[column.field] }}
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>

<app-text-dialog></app-text-dialog>
